<template>
    <div class="edit-info">
        <van-form class="edit-info-form" @submit="onSubmit">
            <van-cell-group>
                <van-field
                    v-model="staffInfo.phone"
                    name="手机"
                    label="手机"
                    placeholder="请输入手机号码"
                    type="tel"
                    :rules="rules.phone"
                />
            </van-cell-group>
            <div class="edit-info-footer">
                <van-button type="primary" native-type="submit" round block :loading="submitLoading">保存</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
import { saveStaffInfo } from '@/api';

export default {
    data() {
        return {
            staffInfo: {
                avatar: '',
                phone: '',
                user_name: ''
            },
            rules: {
                phone: [{ pattern: /^1\d{10}$/, message: '请输入11位手机号码' }]
            },
            submitLoading: false
        };
    },
    created() {
        this.getCardDetail();
    },
    methods: {
        getCardDetail() {
            this.$ajax('get_card_detail')
                .then(({ data }) => {
                    if (data && data?.code == 0 && data?.data) {
                        const { pic_url, user_name, phone } = data.data;
                        this.staffInfo.avatar = pic_url;
                        this.staffInfo.name = user_name;
                        this.staffInfo.phone = phone;
                    } else {
                        return Promise.reject(data);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(err.msg || '获取员工信息失败');
                });
        },
        onSubmit() {
            this.submitLoading = true;
            saveStaffInfo({
                phone: this.staffInfo.phone
            })
                .then(() => {
                    this.$toast.success('保存成功');
                    setTimeout(() => {
                        this.$router.push('/');
                    }, 1500);
                })
                .catch((error) => {
                    this.$toast.fail(error.msg || '保存失败');
                })
                .finally(() => {
                    this.submitLoading = false;
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.edit-info {
    padding: 0.32rem 0.32rem 1.12rem;
    &-form {
        border-radius: 0.16rem;
        overflow: hidden;
        .van-cell {
            padding: 0.28rem 0.32rem;
        }
    }
    &-footer {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 50%;
        right: 0;
        width: 100%;
        transform: translateX(-50%);
        max-width: 480px;
        height: 1.12rem;
        padding: 0 0.32rem;
        background: #fff;
        box-shadow: 0 0 0.16rem 0 rgb(0 0 0 / 8%);
        .van-button {
            background-color: #1472ff;
        }
    }
}
</style>
